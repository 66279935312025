@import 'common';

//////////////////////// sidebar ////////////////////////

aside.sidebar {
  position: fixed;
  right: -400px;
  width: 400px;
  height: calc(100% - var(--header));
  transition: right 1s;
  background: $j-gray;
  font-family: $j-report;
  z-index: 2;
  overflow-y: auto;

  .container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    button.close-button {
      position: fixed;
      top: calc(var(--header) + 10px);
      right: -45px;
      width: 30px;
      padding: unset;
      transform: scale(1.3, 1);
      transition: opacity .2s, right 1s;
      border: 0;
      background: unset;
      color: #000;
      font-family: report,sans-serif;
      font-size: 35px;
      font-weight: 600;
      opacity: $j-light-opacity;

      &:hover {
        cursor: $j-pointer, pointer;
        opacity: $j-dark-opacity;
      }
    }

    img {
      align-self: center;
      width: 200px;
      padding-bottom: 20px;
    }

    p.name {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 19px;
      text-align: center;
    }

    h2 {
      padding-bottom: 4px;
      padding-left: 15px;
      border-radius: 20px;
      background: #000;
      color: #fff;
      font-size: 22px;
      font-weight: 400;
    }

    p.info,
    table {
      margin: 0;
      padding-bottom: 10px;
      padding-left: 12px;
      font-size: 18px;
      font-weight: 300;

      a:hover {
        cursor: $j-pointer, pointer;
      }
    }

    .recipe tr.add {
      display: block;
      margin-top: 10px;
      font-size: 15px;
    }
  }
}

//////////////////////// sidebar positioning ////////////////////////

main.aside-enabled aside.sidebar {
  right: 0;

  button.close-button {
    right: 355px;
  }
}

//////////////////////// media queries ////////////////////////

@media only screen and (max-width: 800px) {
  aside.sidebar {
    right: -100%;
    width: 100%;

    .container button.close-button {
      right: -10%;
    }
  }

  main.aside-enabled  aside.sidebar {
    right: 0;

    button.close-button {
      right: 90%;
    }
  }
}

@import 'common';

section.make-your-own-jewelry-enamel {
    div.preview {
        background-image: url("../../public/images/resources/previews/make-your-own-jewelry-enamel-full.jpg");
    }

    div.image-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        width: 100%;
        max-width: 1540px;

        &.two {
            grid-template-columns: 1fr 1fr;
             img {
                max-height: none;
             }
        }

        img {
            width: 100%;
        }
    }

    ul span{
        display: block;
    }

    a.spotlight {
        color: #000;
    }

    img {
        max-width: 100%;
        max-height: 500px;
    }

    div.image-container div, 
    div.enamel {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: 300;

        @media only screen and (max-width: 800px) {
            font-size: 10px;
        }
    }

    div.enamel img {
        width: 100px;
    }

    div.enamel-container div.enamel {
        padding: 7px;
        width: 115px;
    }

    div.content div.group ol.steps {
        padding-left: 22px;

        li {
            list-style: auto;
        }
    }

    div.content .smaller {
        font-size: 16px;
        font-weight: 500;
    }
}

@import 'common';

//////////////////////// glazes ////////////////////////

section.glazes {
  width: 100%;
  max-width: 100vw;
  margin-right: 0;
  transition: margin-right 1s, width 1s, max-width 1s;

  .glazes-container {
    display: grid;
    justify-content: space-evenly;
    width: 95%;
    padding: 2.5%;
    grid-template-columns: repeat(auto-fit, minmax(240px, max-content));

    button {
      margin: 35px 15px;
      border: unset;
      background-color: unset;
      transition: opacity .5s, border-bottom .7s, scale .5s;
      transition-timing-function: cubic-bezier(0, 0, .08, .97);

      @media (hover: hover) {
        &:hover {
          cursor: $j-pointer, pointer;
          scale: 1.1;
        }
      }

      img {
        width: 190px;
        min-height: 190px;
        padding: 15px 0;
        border-bottom: 4px solid rgba(0, 0, 0, 0);
        justify-self: center;
        scale: 1;

      }

      .caption {
        margin-top: 0;
        font-size: 14px;
        color: #000;
      }
    }
  }

  div.text-container {
    display: flex;
    flex-direction: row;
    height: 44px;
    margin: calc(2.5% + 35px);
    margin-top: 50px;
    margin-bottom: 10px;

    p {
      align-self: center;
      min-width: max-content;
      margin: 0;
      margin-left: auto;
      padding-left: 30px;
      font-size: 19px;
      font-weight: 300;
    }
  }
}

//////////////////////// sidebar positioning ////////////////////////

main.aside-enabled {
  section.glazes {
    max-width: calc(100vw - 400px);
    margin-right: 400px;

    .glazes-container button.selected {
      border-bottom: 4px solid #000;
      cursor: $j-pointer, pointer;
    }

    .glazes-container button:hover {
      cursor: $j-pointer, pointer;
    }
  }
}

//////////////////////// media queries ////////////////////////

@media only screen and (max-width: 700px) {
  section.glazes {
    .glazes-container {
      grid-template-columns: repeat(auto-fit, minmax(170px, max-content));

      button {
        margin: 25px 5px;

        img {
          width: 150px;
          min-height: 150px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  section.glazes {
    div.text-container {
      position: relative;

      p {
        position: absolute;
        right: 0;
        align-self: flex-start;
      }
    }
  }

  main.aside-enabled {
    section.glazes {
      max-width: 100vw;
      margin-right: 0;

      .glazes-container img.selected {
        border-bottom: 4px solid rgba(0, 0, 0, 0);
      }
    }
  }
}

@import 'common';

section.about {
  width: 100%;
  padding: 30px;

  div.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: auto;

    p {
      padding-bottom: 30px;
      font-size: 20px;
      font-weight: 300;
      line-height: 35px;
      text-align: center;
    }

    div.image-block {
      display: flex;
      flex-direction: row;

      div {
        // Block 1
        &.me {
          flex: 1;
          margin-right: 30px;
        }

        &.me2 {
          flex: 1;
        }

        // Block 2
        &.buckets {
          flex: calc(546/651);
          margin-right: 30px;
        }

        &.powders {
          flex: calc(2581/2560);
        }

        // Block 3
        &.mixing {
          flex: calc(828/916);
          margin-right: 30px;
        }

        &.tiles1 {
          flex: calc(4032/3024);
          margin-right: 30px;
        }

        &.tiles2 {
          flex: calc(1526/1181);
        }

        // Block 4
        &.studio2 {
          flex: calc(2991/3492);
          margin-right: 30px;
        }

        &.powders2 {
          flex: calc(3585/2689);
        }

        // Block 5
        &.spreadsheet {
          flex: calc(3024/4032);
          margin-right: 30px;
        }

        &.notebook {
          flex: calc(3024/3218);
        }

        // Block 6
        &.code {
          flex: calc(3024/3157);
          margin-right: 30px;
        }

        &.illustrator {
          flex: calc(3024/3575);
        }
      }
    }

    // General iamges
    img {
      display: block;
      width: auto;
      max-width: 100%;
      max-height: 500px;
      padding-bottom: 30px;
    }
  }
}

@media only screen and (max-width: 650px) {
  section.about div.container div.image-block {
    flex-direction: column;
    flex-wrap: wrap;

    div {
      &.me,
      &.buckets,
      &.mixing,
      &.tiles1,
      &.studio2,
      &.spreadsheet,
      &.code {
        margin-right: 0;
      }
    }

    img {
      max-height: unset;
    }
  }
}

@import 'common';

section.chemical-breakdowns-of-common-materials {
    div.content {
        height: 100px;
        div.scaler {
            transform-origin: 0 0;
            display: grid;
            grid-template-columns: 1fr;
            column-gap: 20px; 
            row-gap: 70px;
            padding-bottom: 80px;
        }
    }
}
@import 'common';

//////////////////////// Add Filter button ////////////////////////

section.glazes div.text-container button.filters {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 145px;
  min-width: 145px;
  height: fit-content;
  max-height: 45px;
  padding: 0;
  transition: scale .2s, box-shadow .2s, border-radius .2s, max-height 1s cubic-bezier(1, .28, .23, .82), min-width 1s cubic-bezier(.1, .49, .31, 1.03), width 1s cubic-bezier(.1, .49, .31, 1.03);
  border: 1.5px solid #000;
  border-radius: 30px;
  background: rgba(#fff, .95);
  font-size: 18px;
  overflow: hidden;
  z-index: 1;
  color: #000;

  &.enabled {
    width: 340px;
    min-width: 340px;
    max-height: 75vh;
    box-shadow: 0 0 5px rgba(#000, .4);
    border-radius: 10px;

    span.open-close {
      top: -5px;
      font-size: 32px;
      rotate: 45deg;
    }
  }

  @media (hover: hover) {
    &.disabled:hover {
      box-shadow: 0 0 5px rgba(#000, .4);
      scale: 1.02;
    }
  }

  span.title {
    padding: 8px 20px 8px 39px;
  }

  span.open-close {
    position: absolute;
    top: 3px;
    left: 20px;
    transition: rotate .7s, top 1s, left 1s, font-size 1s;
    font-size: 22px;

    @media (hover: hover) {
      &:hover {
        color: #000;
      }
    }
  }

  form {
    width: 100%;
    height: 100%;
    text-align: left;
    box-sizing: border-box;
    overflow-y: scroll;

    div.selection-container {
      display: flex;
      margin: 5px;

      div.selection {
        margin: 5px;
        font-size: 14px;
        display: flex;
        min-width: max-content;
        width: 100%;

        fieldset {
          width: 100%;
          padding: 5px;
          padding-right: 10px;
  
          select {
            cursor: $j-default;
            margin-left: 3px;
            width: 100%;
            border: none;
          }
        }
      }

    }

    div.title {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: max-content;
      padding: 10px;
      // border-top: 2px #eee solid;
      background-color: #fff;

      &.expanded, &:hover {
        background-color: $j-gray;
      }

      &:hover > img.arrow {
          opacity: 100%;
      }

      & > * {
        display: inline-block;
      }

      h3 {
        min-width: max-content;
        margin: 0;
        font-weight: 400;
        font-size: 18px;
      }

      div.count {
        width: 22px;
        height: 22px;
        margin-left: 10px;
        border-radius: 11px;
        background: $j-yellow;
        font-size: 15px;
        line-height: 20px;
        text-align: center;

        &.disabled {
          display: none;
        }
      }

      img.arrow {
        width: 10px;
        height: 10px;
        margin-left: auto;
        rotate: 0deg;
        transition: rotate 0.5s, opacity 0.2s;
        opacity: $j-light-opacity;

        &.expanded {
          rotate: -90deg;
        }
      }
    }

    div.category {
      transition: max-height 1.2s;
      overflow-y: hidden;
      background-color: $j-gray;

      div.match-scheme {
        width: 257px;
        min-width: 257px;
        margin: 3px 20px 7px;
        font-size: 14px;

        select {
          margin-right: 5px;
          margin-left: 5px;
          padding: 0 3px;
          border-radius: 10px;
        }
      }

      label {
        display: block;
        min-width: max-content;
        padding: 5px;
        font-size: 18px;

        @media (hover: hover) {
          &:hover {
            background-color: rgba($j-yellow, $j-light-opacity);
          }
        }

        input {
          accent-color: $j-blue;
        }

        span {
          padding-left: 4px;
        }
      }
    }
  }

  button.done {
    display: none;
  }
}

//////////////////////// bubbles ////////////////////////

div.filters {
  display: flex;
  align-self: center;
  width: 100%;
  padding: 0 17px;
  font-size: 15px;
  overflow-x: hidden;
  mask-image: linear-gradient(to right, transparent, #000 17px, #000 80%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, transparent, #000 17px, #000 80%, transparent 100%);

  & > * {
    display: inline-block;
    min-width: max-content;
  }

  button {
    position: relative;
    margin: 5px;
    padding: 5px 15px 7px 35px;
    transition: scale .3s;
    border: 1.5px solid #282828;
    border-radius: 30px;
    background: rgba(#222, .8);
    color: #fff;
    font-weight: 300;

    &.red {
      border-color: rgb(220, 38, 38);
      background: rgba(220, 38, 38, .9);
    }

    &.orange {
      border-color: rgb(242, 124, 22);
      background: rgba(242, 124, 22, .9);
    }

    &.yellow {
      border-color: rgb(254, 190, 0);
      background: rgba(254, 190, 0, .9);
    }

    &.green {
      border-color: rgb(29, 141, 32);
      background: rgba(29, 141, 32, .9);
    }

    &.blue {
      border-color: rgb(58, 128, 226);
      background: rgba(58, 128, 226, .9);
    }

    &.purple {
      border-color: rgb(106, 88, 222);
      background: rgba(106, 88, 222, .9);
    }

    &.pink {
      border-color: rgb(247, 133, 133);
      background: rgba(247, 133, 133, .9);
    }

    &.tanbrown {
      border-color: rgb(131, 70, 41);
      background: rgba(131, 70, 41, .9);
    }

    @media (hover: hover) {
      &:hover {
        scale: .96;

        &  > span {
          opacity: 100%;
        }
      }
    }

    span {
      position: absolute;
      top: 2px;
      left: 15px;
      padding-right: 5px;
      transform: scale(1.3, 1);
      transition: opacity .3s, scale .3s;
      font-family: $j-report, sans-serif;
      font-size: 20px;
      opacity: 20%;
    }
  }
}

//////////////////////// media queries ////////////////////////

@media only screen and (max-width: 800px) {
  section.glazes div.text-container div.filters {
    display: none;
  }
}


@media only screen and (max-width: 700px) {
  section.glazes div.text-container button.filters {
    &.enabled {
      width: 100%;
      min-width: 100%;
      max-height: 65vh !important;

      button.done {
        background-color: $j-blue;
        color: #fff;
      }
    }

    button.done {
      display: inline-block;
      width: 100%;
      height: 40px;
      min-height: 40px;
      border: none;
      background-color: $j-gray;
      color: $j-gray;
      cursor: $j-pointer, pointer;
      transition: background-color 1.5s ease, color 1.5s ease;
      font-size: 16px;
    }
  }
}

@import 'common';

section.home {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  background-image: url("../../public/images/home/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  div {
    flex: auto;
    z-index: 1;

    &.filter {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#fff, .35);
      z-index: auto;
    }

    &.image {
      position: relative;
      width: 27.5%;
      height: 100%;
      box-sizing: border-box;

      img.icon {
        position: absolute;
        height: calc(17vh + 6vw);
        animation-duration: 3s;
        animation-iteration-count: infinite;

        &.teapot {
          &.filled {
            top: 35px;
            left: 40px;
            animation-name: filled-icon;
          }

          &.outline {
            top: 45px;
            left: 30px;
            animation-name: outline-icon;
          }
        }

        &.vase {
          &.filled {
            right: 50px;
            bottom: 40px;
            animation-name: filled-icon;
          }

          &.outline {
            right: 40px;
            bottom: 30px;
            animation-name: outline-icon;
          }
        }
      }
    }

    &.content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 45%;
      min-width: 740px;
      box-sizing: border-box;

      h1 {
        margin-top: 0;
        margin-bottom: 30px;
        transition: letter-spacing 1s;
        transition-timing-function: ease;
        font-family: $j-report;
        font-size: 140px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 150px;
        text-align: center;
        text-shadow: 0 0 20px rgba(#fff, .7);

        @media (hover: hover) {
          &:hover {
            letter-spacing: 4px;
          }
        }
      }

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 560px;
        padding: unset;
        z-index: 1;

        li {
          margin: 0 10px 20px;
          list-style-type: none;

          a {
            display: block;
            width: 250px;
            padding: 5px 0;
            transition: letter-spacing .5s, scale .5s, background-color .2s;
            transition-timing-function: ease;
            border: 3px solid white;
            border-radius: 30px;
            background-color: $j-yellow;
            color: inherit;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0;
            text-align: center;
            text-decoration: unset;
            text-underline-offset: 8px;
            scale: 1;
            box-shadow: 0 0 20px rgba(#000, .3);

            @media (hover: hover) {
              &:hover {
                background-color: $j-teal;
                letter-spacing: .75px;
                cursor: $j-pointer, pointer;
                scale: 1.05;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes filled-icon {
  0%   {rotate: 0deg}
  50%  {rotate: 10deg}
  100% {rotate: 0deg}
}

@keyframes outline-icon {
  0%   {rotate: 5deg}
  50%  {rotate: -5deg}
  100% {rotate: 5deg}
}

@media only screen and (max-width: 1450px) {
  section.home div.image {
    img.icon {
      height: calc(16vh + 6vw);
    }
  }
}

@media only screen and (max-width: 1100px) {
  section.home {
    flex-direction: column;

    div {
      &.image {
        display: none;
      }

      &.content {
        width: 100%;

        ul li a {
          background-color: $j-blue;
          color: #fff;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  section.home div.content h1 {
    width: 100vw;
    font-size: 16vw;
    line-height: 15vw;
  }
}

@media only screen and (max-width: 750px) {
  section.home div.content ul {
    flex-direction: column;
    width: 100%;

    li {
      width: 100%;
      margin: 10px 0;

      a {
        width: 80vw;
        margin: auto;
      }
    }
  }
}

@import 'common';

section.resource.glaze-additive-combo-generator {
  div.content {
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;

    h2 {
      margin-top: 0;
      font-size: 30px;
      font-weight: 500;
    }

    div.column {
      width: 50%;
      margin: 0 20px 20px;

      div.container {
        background-color: $j-gray;
        border-radius: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        table thead th {
          text-align: left;
        }
      }

      &.entry table {
          thead th {
            padding: 0 10px;
            
            &.minus {
              width: 35px;
            }

            &.material {
              width: 75%;
            }

            &.maximum {
              width: 25%;
            }
          }

        .calculate.button {
          margin-top: 25px;
          background-color: $j-teal;
          border-color: #03786a;
          color: white;

          @media (hover: hover) {
            &:hover {
              box-shadow: 0 0 5px rgba(#000, .5);
            }
          }
        }
      }

      &.result div.container {
        align-items: unset;

        h3 {
          margin-bottom: 10px;
        }

        fieldset {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px;
          padding: 15px 15px 25px;
          border-radius: 15px;

          legend {
            padding: 0 10px;
          }

          table {
            &:not(:last-child) {
              border-bottom: 1px solid #000;
            }

            thead th {
              white-space: nowrap;

              &.material {
                width: 250px;
              }
            }

            tbody tr td {
              &.material {
                position: relative;

                span {
                  max-width: 100%;
                  padding-right: 40px;
                  top: 0;
                  position: absolute;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1050px) {
  section.resource.glaze-additive-combo-generator {
    div.content {
      flex-direction: column;
  
      div.column {
        width: 100%;
        margin: 0 0 40px;
        box-sizing: border-box;
      }
    }
  }
}
@import 'common';

section.digitizing-your-glaze-notes {
  div.description a.button {
    margin-right: 15px;
  }

  div.content {
    max-width: 1400px;
  }
}

@media only screen and (max-width: 1390px) {
  section.digitizing-your-glaze-notes {
    div.description a.button {
      margin-right: 15px;
    }
  }
}
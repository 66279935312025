@import 'common';

div.plot-container {
    h3 {
        text-align: center;
        margin: 0 38px 15px;
        font-size: 24px;
        font-weight: 500;
    }

    div.data-container {
        display: flex;

        div.labels {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-right: 4px;

            p {
                position: relative;
                bottom: 16px;
                text-align: right;
                width: 28px;
                font-size: 10px;
                color: gray;
            }
        }

        div.ticks {
            width: 5px;
            height: 394px;
            background-size: 40px 40px;
            background-image: linear-gradient(to bottom, gray 2px, transparent 0.5px);
        }

        div.plot {
            border-bottom: 2px solid black;
            border-left: 2px solid black;
            display: flex;
            padding-right: 20px;

            div.column {
                position: relative;
                display: flex;
                flex-direction: column-reverse;
                margin-left: 20px;
                height: 100%;
                width: 100%;
                cursor: $j-pointer;

                p {
                    position: absolute;
                    text-align: center;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                }

                div.segment {
                    opacity: 1;
                    transition: opacity .2s;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    &.deselected {
                        opacity: 0.25;
                    }
    
                    &.selected {
                        opacity: 1;

                        span 
                        {
                            opacity: 1;
                            transition-duration: 0s; // fast in
                        }
                    }

                    span {
                        position: absolute;
                        font-size: 14px;
                        color: white;
                        text-shadow:
                            1px 0 black,
                            0 1px black,
                            -1px 0 black,
                            0 -1px black,
                            0 0 2px black;
                        opacity: 0;
                        text-wrap: nowrap;
                        // transition: opacity 0.2s; // slow out, I actually don't like this anymore lol
                    }
                }
            }
        } 

        div.legend {
            margin-left: 20px;
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;

            div {
                display: flex;
                align-items: center;
                padding: 2px;
                text-wrap: nowrap;

                div.color {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }
        }

        div.color { // for legend items and column segments
            &.SiO2-Silicon {
                background: $j-teal;
            }
            
            &.Al2O3-Aluminum {
                background: $j-blue;
            }

            &.CaO-Calcium {
                background: $j-pink;
            }

            &.K2O-Potassium {
                background: $j-yellow;
            }

            &.Na2O-Sodium {
                background: $j-red;
            }

            &.Fe2O3-Iron {
                background: $j-plum;
            }

            &.MgO-Magnesium {
                background: $j-purple;
            }

            &.B2O3-Boron {
                background: $j-teal-light;
            }

            &.ZnO-Zinc {
                background: $j-blue-light;
            }

            &.P2O5-Phosphorus {
                background: $j-pink-light;
            }

            &.ZrO2-Zirconium {
                background: $j-yellow-light;
            }

            &.Li2O-Lithium {
                background: $j-plum-light;
            }

            &.TiO2-Titanium {
                background: $j-purple-light;
            }

            &.SrO-Strontium {
                background: lightgray;
            }
        }
    }
}
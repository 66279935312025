@import 'common';

html,
#root {
  height: 100%;
  cursor: $j-default, auto;
}

body {
  height: 100%;
  margin: 0;
}

main {
  display: flex;
  position: relative;
  top: var(--header);
  width: 100%;
  height: calc(100% - var(--header));
  overflow-x: hidden;
}

* {
  font-family: $j-neue;
  letter-spacing: .6px;
}

//////////////////////// header ////////////////////////

header {
  display: flex;
  position: fixed;
  align-items: center;
  width: 100%;
  height: var(--header);
  padding: 8px;
  background: #fff;
  box-shadow: 0 4px 28px 0 #00000040;
  z-index: 1000;
  box-sizing: border-box;

  nav {
    position: fixed;
    left: 0;
    width: 100%;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: unset;

      li {
        margin: 0 4%;
        list-style-type: none;

        &.logo {
          a {
            display: flex;

            img {
              width: 50px;
              cursor: $j-pointer, pointer;
              z-index: 1001;
            }
          }
        }

        &.page {
          transition: letter-spacing .5s, scale .5s;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;

          @media (hover: hover) {
            &:hover {
              cursor: $j-pointer, pointer;
              scale: 1.15;
            }
          }

          a {
            transition: text-decoration-color .5s;
            transition-timing-function: ease;
            color: inherit;
            text-decoration: underline;
            text-underline-offset: 8px;
            text-decoration-color: rgba(0, 0, 0, 0);
            scale: 1;

            @media (hover: hover) {
              &:hover {
                cursor: $j-pointer, pointer;
              }
            }

            @media (hover: hover) {
              &.active {
                text-decoration-color: #000;
              }
            }
          }
        }
      }
    }

    a.email img {
      position: absolute;
      top: 29px;
      right: 36px;
      width: 30px;
      transition: scale .5s;
      cursor: $j-pointer, pointer;

      @media (hover: hover) {
        &:hover {
          scale: 1.15;
        }
      }
    }
  }
}


//////////////////////// media queries ////////////////////////

@media only screen and (max-width: 1000px) {
  header nav a.email {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  header nav ul {
    justify-content: space-evenly;

    li {
      margin: 0;

      &.logo a img {
        width: 40px;
      }

      &.page {
        font-size: 20px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  header nav ul li {
    &.logo a img {
      width: 35px;
    }

    &.page {
      font-size: 16px;
    }
  }
}

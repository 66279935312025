@import 'common';

section.glaze-research-document {
  div.document {
    width: 100%;
    padding-bottom: 40px;

    img {
      display: block;
      width: 100%;
      max-width: 950px;
      margin: auto;
      margin-bottom: 5px;
      box-shadow: 0 0 15px rgb(#000, 15%);
    }
  }
}

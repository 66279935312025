//////////////////////// Variables //////////////////////////////

// Colors
$j-teal: #199989;
$j-blue: #2f40c4;
$j-pink: #e18dac;
$j-yellow: #f9cc50;
$j-red: #d33f49;
$j-plum: #a4026f;
$j-purple: #523b7f;
$j-gray: #f3f3f3;

$j-teal-light: #98cbc4;
$j-blue-light: #969fe2;
$j-pink-light: #f1c5d5;
$j-yellow-light: #fce5ab;
$j-red-light: #eba1a5;
$j-plum-light: #d48bb7;
$j-purple-light: #a89cbe;

$j-pink-darker: #e086a7;
$j-yellow-hover: #f5ba1c;

// Fonts
$j-report: report, sans-serif;
$j-neue: neue-haas-unica, sans-serif;

// Opacities
$j-light-opacity: .4;
$j-dark-opacity: .7;

// Cursors
$j-default: url('../../public/svgs/default.svg');
$j-pointer: url('../../public/svgs/pointer.svg');

// Sizes
:root {
  --header: #{80px};
}

@media only screen and (max-width: 700px) {
  :root {
    --header: #{70px};
  }
}

@media only screen and (max-width: 500px) {
  :root {
    --header: #{60px};
  }
}

///////////////////////// Styles /////////////////////////////

// Cursor
a {
  cursor: $j-pointer, pointer;
}

a.button,
button,
label {
  cursor: $j-default, default;
}

// Horizontal line
hr {
  width: 100%;
  height: 3px;
  margin: 0;
  border: unset;
  background: $j-yellow;
}

// In Progress (Temporary)
.in-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100% - var(--header));
  padding: 30px;
  color: $j-teal;
  font-size: 40px;
  text-align: center;
  box-sizing: border-box;
}

// Resource
section.resource {
  width: 100%;
  
  div.preview {
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  div.description {
    box-sizing: border-box;
    padding: 10px 40px 60px 40px;

    h1 {
      margin-bottom: 10px;
      font-size: 40px;
      font-weight: 500;
    }

    p {
      font-size: 20px;
      font-weight: 300;
    }
  }

  div.content {
    padding: 0 40px 30px 40px;

    h2 {
      margin-top: 50px;
      font-size: 30px;
      font-weight: 500;

      &:first-of-type {
        margin-top: 0;
      }
    }

    div.group {
      margin-left: 3px;
      padding-right: 80px;
      padding-left: 40px;
      border-left: #000 solid 3px;

      ul,
      ol {
        padding: 0;

        li {
          margin: 0;
          padding: 8px 0;
          list-style-type: none;

          h3 {
            width: fit-content;
            padding: 5px 20px;
            border-radius: 20px;
            background-color: #000;
            color: #fff;
          }

          .section {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding-bottom: 10px;

            img {
              width: 40%;
              min-width: 40%;
              max-width: 700px;
              height: auto;
              margin-right: 40px;
              box-shadow: 0 0 15px rgb(#000, 15%);
            }

            p {
              min-width: 60%;
            }
          }
        }
      }
    }

    p {
      font-size: 20px;
      font-weight: 300;
    }
  }


  @media only screen and (max-width: 1390px) {
    div.content div.group {
      margin-left: 0;
      padding: 0;
      border-left: unset;

      ul,
      ol {
        li {
          .section {
            flex-direction: column;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  
  @media only screen and (max-width: 600px) {
    div.description {
      padding: 10px 30px 60px 30px;
    }

    div.content {
      padding: 0 30px;
      padding-bottom: 30px;
    }
  }
}

// Button
.button {
  display: inline-block;
  margin: 5px 0;
  padding: 10px 17px;
  transition: scale .2s, background-color .2s, border-color .2s, box-shadow .2s;
  border: 1.5px solid #000;
  border-radius: 30px;
  background-color: #fff;
  color: #000;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  scale: 1;
  box-shadow: 0 0 0 #000;

  @media (hover: hover) {
    &:hover {
      border-color: #dfad22;
      background-color: $j-yellow;
      scale: 1.06;
    }
  }

  img {
    width: 14px;
    margin-right: 5px;
  }
}

code {
  background: $j-gray;
}